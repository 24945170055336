<template>
	<div class="container">
		<GModule>
			<GTitle name="第三方账号">
				<div class="fra hm-hd">
				  <a-button class="clo2">导出</a-button>
				</div>
			</GTitle>
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500,y:250 }" 
				bordered
				:pagination="false">
			</a-table>
			<div class="documentFt">
			  <a-pagination size="small" :total="50" />
			</div>
		</GModule>
	</div>
</template>

<script>
export default{
	data(){
		return{
			columnsHe: [{
			    title: '序号',
			    width: 100,
			    dataIndex: '第三方名称',
			    key: 'createTime',
			    fixed: 'left',
			  }, {
			    title: '注册地址',
			    width: 100,
			    dataIndex: 'name',
			    key: 'name',
			    fixed: 'left',
			  }, {
			    title: '注册完成后账号',
			    width: 120,
			    dataIndex: 'describe',
			    key: 'describe'
			  }, {
			    title: '登录密码',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }, {
			    title: '开发者ID',
			    width: 100,
			    dataIndex: 'name',
			    key: 'name',
			    fixed: 'left',
			  }, {
			    title: '开发者密码',
			    width: 120,
			    dataIndex: 'describe',
			    key: 'describe'
			  }, {
			    title: '原始ID',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }, {
			    title: '商户密钥',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }],
		}
	}
}
</script>

<style scoped lang="scss">
.container{
	margin: 20px;
	padding: 0;
	overflow: hidden;
}
</style>